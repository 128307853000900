import React from "react";
import { Button, Col, Divider, Flex, Row, Statistic } from "antd";
import { Bar, Column } from "@ant-design/plots";
import "./ReportsPage.css";

type LegendCfg = {
  position:
    | "bottom-right"
    | "top"
    | "top-left"
    | "top-right"
    | "right"
    | "right-top"
    | "right-bottom"
    | "left"
    | "left-top"
    | "left-bottom"
    | "bottom"
    | "bottom-left"
    | undefined;
};

function ReportsPage() {
  const AlertsByPlatformBarChart = () => {
    const data = [
      { platform: "Okta", value: 27 },
      { platform: "Microsoft Sentinel", value: 25 },
      { platform: "Azure Purview", value: 18 },
      { platform: "Microsoft AD", value: 15 },
      { platform: "Other", value: 15 },
    ];
    const legendCfg: LegendCfg = {
      position: "top-left",
    };

    const config = {
      data,
      xField: "value",
      yField: "platform",
      seriesField: "platform",
      legend: legendCfg, // Provide a value for the legend proper
    };
    return <Bar {...config} />;
  };

  const TopEntitiesByAlerts = () => {
    const data = [
      { entity: "John Doe", type: "User", value: 5 },
      { entity: "Joe Wilson", type: "User", value: 3 },
      { entity: "Jack Smith", type: "User", value: 2 },
      { entity: "James Jones", type: "User", value: 10 },
      { entity: "Sensitive Reports Downloaded", type: "Alert", value: 7 },
      { entity: "Privilege Escalation", type: "Alert", value: 10 },
      { entity: "Impossible Travel", type: "Alert", value: 3 },
      { entity: "208.184.176.210", type: "IP Address", value: 5 },
      { entity: "208.184.222.738", type: "IP Address", value: 3 },
      { entity: "196.235.262.346", type: "IP Address", value: 2 },
    ];
    const legendCfg: { position: "middle" } = {
      position: "middle",
    };

    const config = {
      data,
      isStack: true,
      xField: "value",
      yField: "type",
      seriesField: "entity",
      label: {
        ...legendCfg,
        layout: [
          {
            type: "interval-adjust-position",
          },
          {
            type: "interval-hide-overlap",
          },
          {
            type: "adjust-color",
          },
        ],
      },
    };
    return <Bar {...config} />;
  };

  const TPVsFPBreakdownBarChart = () => {
    const data = [
      { alertType: "Insider Risk", type: "TP", value: 27 },
      { alertType: "Insider Risk", type: "FP", value: 25 },
      { alertType: "Sensitive Reports Downloaded", type: "TP", value: 18 },
      { alertType: "Sensitive Reports Downloaded", type: "FP", value: 15 },
      { alertType: "Impossible Travel", type: "TP", value: 40 },
      { alertType: "Impossible Travel", type: "FP", value: 10 },
      { alertType: "Privilege Escalation", type: "TP", value: 15 },
      { alertType: "Privilege Escalation", type: "FP", value: 8 },
      { alertType: "Phishing", type: "TP", value: 30 },
      { alertType: "Phishing", type: "FP", value: 15 },
      { alertType: "Malware", type: "TP", value: 15 },
      { alertType: "Malware", type: "FP", value: 8 },
      { alertType: "Data Exfiltration", type: "TP", value: 2 },
      { alertType: "Data Exfiltration", type: "FP", value: 8 },
    ];
    const legendCfg: { position: "middle" } = {
      position: "middle",
    };
    const config = {
      data,
      width: 1200,
      isGroup: true,
      xField: "alertType",
      yField: "value",
      seriesField: "type",
      label: {
        ...legendCfg,
        layout: [
          { type: "interval-adjust-position" },
          { type: "interval-hide-overlap" },
          { type: "adjust-color" },
        ],
      },
    };
    return <Column {...config} />;
  };

  return (
    <div className="page">
      <div className="page-description">
        <Flex justify={"space-between"} align={"flex-start"}>
          <p>Summary statistics and reports for your organization.</p>
          <Button>Export to CSV</Button>
        </Flex>
      </div>
      <Divider />
      <h2 className="section-title">SOC productivity</h2>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="# of alerts reviewed" value={112} />
        </Col>
        <Col span={12}>
          <Statistic title="# of TPs identified" value={85} />
        </Col>
        <Col span={12}>
          <Statistic
            title="Average time of analyst review"
            value={10}
            suffix={"hours"}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="% Accepted vs Denied"
            value={90}
            precision={2}
            suffix={"%"}
          />
        </Col>
        <Col span={12}>
          <Statistic title="# of feedback provided by analyst" value={22} />
        </Col>
      </Row>
      <Divider />
      <h2 className="section-title">Alert Insights</h2>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <h3># of alerts segmented by platform</h3>
          <AlertsByPlatformBarChart />
        </Col>
        <Col xs={24} md={12}>
          <h3>Top entities with alerts triggered</h3>
          <TopEntitiesByAlerts />
        </Col>
      </Row>
      <h3>TP vs FP breakdown</h3>
      <TPVsFPBreakdownBarChart />

      <Divider />
      <h2 className="section-title">System Insights</h2>
      <Row className="system-insights-section">
        <Col span={12}>
          <Statistic title="# of platforms connected" value={6} />
        </Col>
        <Col span={12}>
          <Statistic title="# of attributes stored in KBs" value={112526} />
        </Col>
        <Col span={12}>
          <Statistic title="# of events stored in KBs" value={802} />
        </Col>
        <Col span={12}>
          <Statistic title="# of model updates in last 30 days" value={10} />
        </Col>
      </Row>
    </div>
  );
}

export default ReportsPage;
