export const capitalize = (s: string) =>
  s ? s[0].toUpperCase() + s.slice(1) : "";

export const renderLogo = (logoString: string) => {
  switch (logoString.toLowerCase()) {
    case "workday":
      return (
        <img
          className="logo"
          src="./workday-logo.png"
          alt="Workday Logo"
          style={{ maxWidth: 150, maxHeight: 60 }}
        />
      );
    case "okta":
      return (
        <img
          className="logo"
          style={{ width: "50%", maxWidth: "100px" }}
          src="./okta-logo.png"
          alt="Okta Logo"
        />
      );
    case "microsoft ad":
      return (
        <img
          className="logo"
          style={{ maxWidth: 200, maxHeight: 60 }}
          src="./microsoft-ad-logo.png"
          alt="Microsoft AD Logo"
        />
      );
    case "microsoft purview":
      return (
        <img
          className="logo"
          src="./microsoft-purview-logo.jpeg"
          alt="Microsoft Purview Logo"
        />
      );
    case "m365":
      return (
        <img
          className="logo"
          style={{ maxHeight: "100%" }}
          src="./m365-logo.jpg"
          alt="Microsoft 365 Logo"
        />
      );
    case "sfdc":
      return (
        <img
          className="logo"
          style={{ maxWidth: 200, maxHeight: 150 }}
          src="./sfdc-logo.png"
          alt="Salesforce Logo"
        />
      );
    case "virus total":
      return (
        <img
          className="logo"
          style={{ maxWidth: 200, maxHeight: 150 }}
          src="./virus-total-logo.png"
          alt="Virus Total Logo"
        />
      );
    case "ip quality score":
      return (
        <img
          className="logo"
          style={{ maxWidth: 200, maxHeight: 120 }}
          src="./ip-quality-score-logo.png"
          alt="IP Quality Score Logo"
        />
      );
    case "splunk":
      return <img className="logo" src="./splunk-logo.png" alt="Splunk Logo" />;
    case "panther":
      return (
        <img className="logo" src="./panther-logo.png" alt="Panther Logo" />
      );
    case "microsoft sentinel":
      return (
        <img
          className="logo"
          style={{ maxHeight: 60 }}
          src="./microsoft-sentinel-logo.png"
          alt="Microsoft Sentinel Logo"
        />
      );
    case "exabeam":
      return (
        <img
          className="logo"
          style={{ maxHeight: 100, maxWidth: 180 }}
          src="./exabeam-logo.png"
          alt="Exa Beam Logo"
        />
      );
    default:
      return null;
  }
};

export function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}
