import React, { useState } from "react";

import {
  Button,
  Card,
  Divider,
  ConfigProvider,
  Form,
  Input,
  Row,
  Modal,
  notification,
} from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { renderLogo } from "./utils";
import "./IntegrationsPage.css";

const { Meta } = Card;
const IntegrationCard = (props: any) => {
  const theme = {
    components: {
      Button: {
        primaryColor: "green",
        linkHoverBg: "green",
        textHoverBg: "green",
        defaultBorderColor: "green",
        groupBorderColor: "green",
      },
    },
  };

  return (
    <Card
      style={{ width: 300 }}
      cover={renderLogo(props.icon)}
      actions={[
        <SettingOutlined key="setting" />,
        <EditOutlined key="edit" />,
        <EllipsisOutlined key="ellipsis" />,
      ]}
    >
      <Meta
        title={props.title}
        description={
          <div>
            {props.description}
            <br />
            {props.connected ? (
              <ConfigProvider theme={theme}>
                <Button className="connected-btn" style={{ color: "green" }}>
                  <CheckOutlined /> Connected
                </Button>
              </ConfigProvider>
            ) : (
              <Button
                className="connect-btn"
                onClick={() => props.handleOpenModal(props)}
              >
                <PlusOutlined /> Connect
              </Button>
            )}
          </div>
        }
      />
    </Card>
  );
};

const initialIntegrations = [
  {
    title: "Splunk Log Collector",
    description: "SIEM for log collection",
    icon: "splunk",
    connected: false,
  },
  {
    title: "Panther Log Collector",
    description: "SIEM for log collection",
    icon: "panther",
    connected: false,
  },
  {
    title: "Microsoft Sentinel Log Collector",
    description: "SIEM for log collection",
    icon: "microsoft sentinel",
    connected: true,
  },
  {
    title: "Exabeam Log Collector",
    description: "SIEM for log collection",
    icon: "exabeam",
    connected: false,
  },
  {
    title: "Workday API",
    description: "HR employee data",
    icon: "workday",
    connected: true,
  },
  {
    title: "Okta API",
    description: "Identity and access data",
    icon: "okta",
    connected: true,
  },
  {
    title: "Microsoft AD API",
    description: "Active Directory for employee data",
    icon: "microsoft ad",
    connected: true,
  },
  {
    title: "Microsoft Purview API",
    description: "Data governance for employee data",
    icon: "microsoft purview",
    connected: true,
  },
  {
    title: "Microsoft 365 API",
    description: "Office 365 for employee data",
    icon: "m365",
    connected: true,
  },
  {
    title: "Salesforce API ",
    description: "CRM for employee data",
    icon: "sfdc",
    connected: true,
  },
  {
    title: "Virus Total API",
    description: "Virus scanning",
    icon: "virus total",
    connected: true,
  },
  {
    title: "IP Quality Score API",
    description: "Threat intelligence for IP",
    icon: "ip quality score",
    connected: true,
  },
];

function IntegrationsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [integrations, setIntegrations] = useState(initialIntegrations);
  const [selectedIntegration, setSelectedIntegration] = useState({} as any);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api["success"]({
      message: "Integration Connected",
      description: "Thanks for adding a new integration",
      duration: 5,
    });
  };
  const setIntegrationConnected = (integrationTitle: any) => {
    const updatedIntegrations = integrations.map((integration) => {
      if (integration.title === integrationTitle) {
        return { ...integration, connected: true };
      }
      return integration;
    });
    setIntegrations(updatedIntegrations);
  };
  const handleOpenModal = (integration: any) => {
    setSelectedIntegration(integration);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIntegrationConnected(selectedIntegration.title);
    setIsModalOpen(false);
    openNotification();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };
  return (
    <div className="page">
      <div className="page-description">
        Integrate with your SIEM and other data providers
      </div>
      <Row className="integrations" gutter={16} justify={"space-evenly"}>
        {integrations.map((integration) => (
          <IntegrationCard
            key={integration.title}
            {...integration}
            handleOpenModal={handleOpenModal}
          />
        ))}
      </Row>
      <Modal
        className="integration-modal"
        title={selectedIntegration.title}
        open={isModalOpen}
        onOk={handleOk}
        okText={"Submit"}
        onCancel={handleCancel}
      >
        <div className="integration-description">
          {selectedIntegration.description}
        </div>
        <Divider style={{ marginTop: 10 }} />
        <Form
          {...layout}
          form={form}
          name="integration-form"
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="api-key"
            label="API Key"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="api-secret"
            label="API Secret"
            rules={[{ required: true }]}
          >
            <Input type="password" />
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </div>
  );
}

export default IntegrationsPage;
