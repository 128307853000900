import React from "react";
import { Tooltip, Badge } from "antd";

function RiskScore(props: any) {
  const getRiskColor = (riskScore: number) => {
    switch (true) {
      case riskScore < 20:
        return "#1677ff";
      case riskScore < 90:
        return "#faad14";
      default:
        return "#f5222d";
    }
  };
  return (
    <Tooltip title={props.riskExplanation}>
      <Badge
        count={props.riskScore}
        title={props.riskExplanation}
        color={getRiskColor(props.riskScore)}
      />
    </Tooltip>
  );
}

export default RiskScore;
