import React, { useState } from "react";
import { Button, Descriptions, List, Flex, Skeleton, Tag } from "antd";
import DetailPage from "./DetailPage";
import RiskScore from "./components/RiskScore";
import { renderLogo } from "./utils";

// data
import data from "./data/examples.json";

function AlertsPage() {
  const [detail, setDetail] = useState({});
  const [showDetail, setShowDetail] = useState(false);

  const selectShowDetail = (selectedDetail: object) => {
    setDetail(selectedDetail);
    setShowDetail(true);
  };
  const list = data.sort((a: any, b: any) => b.riskScore - a.riskScore);

  return (
    <div className="page">
      {showDetail ? (
        <DetailPage data={detail} setShowDetail={setShowDetail} />
      ) : (
        <div className="list-view">
          <Flex
            className="alert-title-section"
            justify={"space-between"}
            align={"flex-start"}
          >
            <h4>SIEM Alerts in the last 7 days</h4>
          </Flex>

          <List
            className="alert-list"
            itemLayout="horizontal"
            dataSource={list}
            pagination={{
              defaultCurrent: 1,
              total: 3,
            }}
            renderItem={(item: any) => (
              <List.Item
                actions={[
                  <Button
                    key="list-loadmore-edit"
                    onClick={() => selectShowDetail(item.detail)}
                  >
                    Review Alert
                  </Button>,
                ]}
                className="alert-list-item"
                style={{
                  padding: 20,
                }}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <Descriptions
                    title={
                      <p>
                        <RiskScore
                          riskExplanation={item.detail.summary.riskExplanation}
                          riskScore={item.detail.summary.riskScore}
                        />{" "}
                        {item.title}
                      </p>
                    }
                    column={4}
                    items={[
                      {
                        key: "1",
                        label: "time",
                        span: 4,
                        children: item.time,
                      },
                      {
                        key: "2",
                        label: "log id",
                        span: 4,
                        children: <code>{item.raw_log.uuid}</code>,
                      },
                      {
                        key: "3",
                        label: "entities",
                        span: 4,
                        children: item.entities
                          .split(",")
                          .map((e: string, index: number) => (
                            <Tag key={index}>{e}</Tag>
                          )),
                      },
                      {
                        key: "4",
                        label: "source",
                        children: renderLogo(item.source),
                      },
                    ]}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
}

export default AlertsPage;
