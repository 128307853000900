import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Collapse,
  Flex,
  Input,
  List,
  Modal,
  Space,
  Table,
  Tabs,
  Tag,
  Tree,
  notification,
} from "antd";

import type { DataNode } from "antd/es/tree";
import {
  PlusSquareOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import JSONPretty from "react-json-pretty";
import { capitalize, renderLogo } from "./utils";
import RiskScore from "./components/RiskScore";
import SiemAlertSeverity from "./components/SiemAlertSeverity";

import "react-json-pretty/themes/monikai.css";
import "./DetailPage.css";

const { TextArea } = Input;

const DetailCard = (props: any) => (
  <Card
    id={props.id}
    className="detail-section"
    hoverable={true}
    bordered={false}
    title={props.title}
    headStyle={{ fontSize: "22px", fontWeight: "bold", color: "#030080" }}
  >
    {props.children}
  </Card>
);

const Summary = (props: any) => {
  const columns = [
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: "Entity Type",
      dataIndex: "entity_type",
      key: "entity_type",
      render: (text: string) => <Tag>{text.toUpperCase()}</Tag>,
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      render: (text: string) => capitalize(text),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Knowledge Base",
      dataIndex: "knowledge_base",
      key: "knowledge_base",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text: string) => renderLogo(text),
    },
  ];
  return (
    <div className="tab-content">
      <Flex justify={"flex-end"} align={"flex-start"}>
        <Button onClick={props.acceptFeedback}>Accept</Button>
        <Button onClick={props.denyFeedback}>Deny</Button>
      </Flex>
      <DetailCard id="alert-description" title="Alert Description">
        <p className="summary-section">{props.summary.description}</p>
        <Collapse
          ghost
          size="small"
          items={[
            {
              key: "view-raw-log",
              label: <b>View Raw Log</b>,
              children: (
                <JSONPretty
                  id={props.summary.title}
                  data={props.summary.raw_log}
                />
              ),
            },
          ]}
        />
      </DetailCard>
      <DetailCard id="analysis" title={"Analysis"}>
        <Space size={[20, 0]} wrap>
          <p>
            <b>Risk Score:</b>{" "}
            <RiskScore
              riskExplanation={props.summary.riskExplanation}
              riskScore={props.summary.riskScore}
            />
          </p>
          <p>
            <b>Alert Severity from SIEM:</b>{" "}
            <SiemAlertSeverity severity={props.summary.siemAlertSeverity} />
          </p>
          <p>
            <b>Tags:</b>{" "}
            {props.summary.categories.map((c: string) => (
              <Tag>{c}</Tag>
            ))}
          </p>
        </Space>
        <ul className="ul-summary-section">
          {props.summary.analysis.map((item: any) => (
            <li key={item.key}>{item.description}</li>
          ))}
        </ul>
      </DetailCard>
      <DetailCard id="potential-impact" title={"Potential Impact"}>
        <ul className="ul-summary-section">
          {props.summary.impact.map((item: any) => (
            <li key={item.key}>{item.description}</li>
          ))}
        </ul>
      </DetailCard>
      <DetailCard id="recommendations" title={"Recommendations"}>
        <List
          className="summary-section"
          size="large"
          dataSource={props.summary.recommendations}
          renderItem={(item: any, index) => (
            <List.Item key={item.key}>
              <List.Item.Meta
                title={`${index + 1}. ${item.action}`}
                description={item.rationale}
              />
              <Tag className="recommendation-type" color="blue">
                {item.type}
              </Tag>
            </List.Item>
          )}
        />
      </DetailCard>
      <DetailCard id="key-insights" title={"Key Insights"}>
        <Table
          className="summary-section key-insights-table"
          dataSource={props.summary.key_insights}
          columns={columns}
          pagination={false}
        />
      </DetailCard>
    </div>
  );
};

const formatTaskOutput = (output: string | object) => {
  switch (typeof output) {
    case "string":
      return output;
    case "object":
      return JSON.stringify(output, null, 2);
  }
};

const AgentTask = (props: any) => (
  <div>
    <Collapse
      ghost
      items={[
        {
          label: <b>{props.task}</b>,
          children: (
            <div>
              <Collapse
                ghost
                size="small"
                items={[
                  {
                    key: "task-dependent-panel" + props.task,
                    label: "Dependent Task Output",
                    children: (
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {formatTaskOutput(props.dependent_task_output)}
                      </p>
                    ),
                  },
                ]}
              />
              <Collapse
                ghost
                size="small"
                items={[
                  {
                    key: "output-panel" + props.task,
                    label: "Task Output",
                    children: (
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {formatTaskOutput(props.output)}
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          ),
        },
      ]}
    />
  </div>
);
const createTreeNode = (step: any) => {
  if (step.children && step.children.length > 0) {
    step.children = step.children.map((child: any) => createTreeNode(child));
  }
  return {
    ...step,
    title: <AgentTask {...step} />,
    key: String(step.id),
  };
};

const AgentWorkflow = (props: any) => {
  const treeData: DataNode[] = props.agent.steps.map((step: any) => {
    return createTreeNode(step);
  });
  return (
    <div className="tab-content">
      {/* <p>
                This is an interactive transcript of the tasks generated and executed by
                the Agent to summarize this alert.
            </p>
            <br /> */}
      <DetailCard title="Agent Workflow">
        <h3>Objective</h3>
        <p>{props.agent.objective}</p>
        <h3>AI-generated Task List</h3>
        <ol>
          {props.agent.steps.map((step: any) => (
            <li style={{ whiteSpace: "break-spaces" }} key={step.id}>
              {step.task}
            </li>
          ))}
        </ol>
        <h3>Agent Workflow Task Tree</h3>
        <p>
          Click on the <RightOutlined /> icon to expand the task output to view
          the Agent's action. Click on the <PlusSquareOutlined /> icon to expand
          and view sub-tasks.
        </p>
        <Tree showLine defaultExpandAll treeData={treeData} />
      </DetailCard>
    </div>
  );
};

const DetailPage = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api["success"]({
      message: "Feedback Submitted",
      description: "Thanks for submitting your feedback!",
      duration: 5,
    });
  };
  const acceptFeedback = () => {
    setIsModalOpen(true);
    setFeedback("Accept");
  };
  const denyFeedback = () => {
    setIsModalOpen(true);
    setFeedback("Deny");
  };
  const handleOk = () => {
    setIsModalOpen(false);
    openNotification();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const tabs = [
    {
      key: "tab-1",
      label: "Summary",
      children: (
        <Summary
          summary={props.data.summary}
          acceptFeedback={acceptFeedback}
          denyFeedback={denyFeedback}
        />
      ),
    },
    {
      key: "tab-2",
      label: "Agent Workflow",
      children: <AgentWorkflow agent={props.data.agent_transcript} />,
    },
  ];
  return (
    <div>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            title: (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => props.setShowDetail(false)}
              >
                <LeftOutlined /> Back
              </p>
            ),
          },
        ]}
      />
      <Tabs defaultActiveKey="tab-1" items={tabs} />
      <Modal
        title={capitalize(feedback)}
        open={isModalOpen}
        onOk={handleOk}
        okText={"Submit"}
        onCancel={handleCancel}
      >
        <p>
          Please add additional feedback on the agent’s performance and how it
          can be improved. This feedback will flow back to our systems to
          generate better results in the future.
        </p>
        <TextArea rows={4} placeholder="Feedback" />
      </Modal>
    </div>
  );
};
export default DetailPage;
