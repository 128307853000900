import React from "react";
import { Tag } from "antd";

function SiemAlertSeverity(props: any) {
  const getSeverityColor = (severity: string) => {
    switch (severity) {
      case "high":
        return "error";
      case "medium":
        return "warning";
      default:
        return "processing";
    }
  };
  return <Tag color={getSeverityColor(props.severity)}>{props.severity}</Tag>;
}

export default SiemAlertSeverity;
