import React, { useState } from "react";
import { Layout, Menu } from "antd";
import {
  InfoCircleOutlined,
  BarChartOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { Outlet, Link, useLocation } from "react-router-dom";
import { capitalize } from "./utils";

import "./App.css";

const { Header, Content, Sider } = Layout;

function App() {
  const routeName = capitalize(useLocation().pathname.replace("/", ""));
  const [menuItem, setMenuItem] = useState(routeName || "Integrations");
  console.log(routeName);
  return (
    <Layout>
      <Sider breakpoint="lg" collapsedWidth="0" className="sider">
        <div className="demo-logo-vertical">
          <h3 className="company-title">Lucid Security</h3>
          <div className="app-title">Demo</div>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[routeName || "Integrations"]}
          items={[
            {
              key: "Integrations",
              label: <Link to={"/integrations"}>Integrations</Link>,
              icon: <AppstoreAddOutlined />,
            },
            {
              key: "Alerts",
              label: <Link to={"/alerts"}>Alerts</Link>,
              icon: <InfoCircleOutlined />,
            },
            {
              key: "Reports",
              label: <Link to={"/reports"}>Reports</Link>,
              icon: <BarChartOutlined />,
            },
          ]}
          onSelect={({ key }) => setMenuItem(key)}
        />
      </Sider>
      <Content>
        <Header className="app-header">{menuItem}</Header>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default App;
